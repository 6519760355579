@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.add-preview {

  .description-section {
    height: utils.rem(141);

    .input__input-wrapper,
    .form-row {
      margin: 0;
    }

    textarea {
      border: none;
    }
  }

  &__documents {
    margin-top: utils.bu(2.5);
    margin-bottom: utils.bu(6);
    gap: utils.bu(2.5);
    display: flex;
    flex-direction: column;
  }

  .members-section,
  .classes-section {

    .table {
      @include mixins.set-font-family('SemplicitaPro');

      .table-cell {
        font-size: utils.rem(10);
        color: rgba(map-get(colors.$text-color, rich-black), .87);
        font-weight: 400;
      }

      .table-cell:first-child {
        font-weight: 500;
        font-size: utils.rem(14);
        color: rgba(map-get(colors.$text-color, rich-black), .87);
      }
    }
  }

  .event-details-section {
    padding: 0 utils.bu(3) utils.bu(3);

    .form {

      .radio-group + .input,
      .input + .select,
      .select + .input,
      .select + .radio-group,
      .radio-group + .radio-group,
      .input + .input {
        margin-left: utils.rem(110);
      }

      .form-row:last-child {
        align-items: flex-start;

        .input {
          width: 50%;
        }
      }
    }
  }

  &__entry-form,
  &__points-config {
    margin-top: utils.rem(50);

    .details-section:last-child {
      margin-top: 0;
    }
  }
}
