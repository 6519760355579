@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.entry-details {
  margin-top: utils.rem(35);
  position: relative;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  padding-bottom: utils.bu(2);

  &__context {
    display: flex;
    position: fixed;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 207px);
    overflow-y: scroll;
    padding: 0 utils.bu(2) utils.rem(76) utils.rem(10);
    margin-left: utils.rem(-10);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &-actions {
      width: 100%;
      margin-top: utils.bu(5);
    }
  }

  &__personal-details {
    margin-bottom: utils.bu(5);

    .form-row:first-child {
      margin-top: 0;
    }
  }

  &__total-due {
    margin-left: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: utils.rem(28) utils.rem(42) utils.rem(15);
    width: utils.rem(362);

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: utils.bu(2) 0;

      span {
        font-weight: 600;
        margin-right: utils.rem(96);
      }

      p {
        text-align: right;
      }
    }
  }

  &__detail {
    margin-left: utils.rem(321);
    flex: 2;

    .accordion {
      margin-bottom: utils.rem(60);

      .details-section {
        margin-bottom: utils.rem(60);

        .table {
          border: utils.rem(1) solid rgba(map-get(colors.$primary-color, black), .1);
          border-radius: utils.bu(1);
          border-spacing: 0;
          border-collapse: separate;

          tr {
            border-bottom: utils.rem(1) solid rgba(map-get(colors.$primary-color, black), .1);

            td {

              > section > p {
                margin-bottom: utils.bu(.5);
              }

              > section > small {
                color: map-get(colors.$primary-color, primary);
                @include mixins.set-font-family('SemplicitaPro');
              }
            }

            &:last-of-type {

              td {

                &:first-child {
                  border-bottom-left-radius: utils.bu(1);
                }

                &:last-child {
                  border-bottom-right-radius: utils.bu(1);
                }
              }
            }
          }
        }

        .table__header {
          background-color: map-get(colors.$primary-color, primary);
          color: map-get(colors.$primary-color, white);

          th:first-child {
            border-top-left-radius: utils.rem(8);
            border-bottom-left-radius: utils.rem(8);
          }

          th:last-child {
            border-top-right-radius: utils.rem(8);
            border-bottom-right-radius: utils.rem(8);
          }
        }

        .card {
          padding: 0;
          box-shadow: none;
        }
      }

      &:last-child {

        .accordion__content--open {
          padding: utils.rem(12) 0 utils.rem(12);
        }
      }
    }
  }
}
