@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.form-canvas {
  width: 100%;
  $block: &;

  &__element {
    width: 100%;
    position: relative;
    @include mixins.svg-hover;

    .checkbox-group {

      &__description {
        margin-top: utils.bu(3.5);
      }
    }

    > svg {
      position: absolute;
      top: 0;
      right: 0;
      width: utils.rem(30);
      height: utils.rem(30);
      color: map-get(colors.$primary-color, primary);
    }
  }

  &__section {

    &--active {

      .card {
        border: utils.rem(1) solid map-get(colors.$primary-color, primary);
      }
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include mixins.svg-hover;

      > svg {
        color: map-get(colors.$primary-color, primary);
      }

      &--active {

        #{$block}__section-title {
          cursor: pointer;
        }

        > svg {
          color: rgba(map-get(colors.$text-color, secondary), .87);
        }

        #{$block}__delete-icon {
          color: map-get(colors.$primary-color, error);
        }
      }
    }

    .form {

      .form-row {
        margin-top: utils.rem(40);
      }

      .form-row:first-of-type {
        margin-top: utils.rem(45);
      }

      .dropdown,
      .dropdown__trigger {
        width: 100%;
      }

      .radio-group__label {
        padding-left: 0;
      }
    }

    .details-section {
      margin-top: 0;
      margin-bottom: utils.rem(30);

      .card {
        padding: utils.rem(45) utils.rem(52);
      }
    }
  }

  &--editable {

    .details-section {

      &__header {
        margin-bottom: 0;
      }
    }
  }
}
