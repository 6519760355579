@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.unverified-message {
  display: flex;
  flex-direction: column;
  margin-top: utils.rem(25);

  ul {
    list-style-type: disc;
    margin-left: utils.rem(25);
  }

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: utils.rem(39);

    & > svg {
      color: map-get(colors.$text-color, error);
      margin-right: utils.rem(13);
    }

    &-text {
      font-size: utils.rem(20);
      font-weight: 400;
    }
  }

  .card {
    border: utils.rem(1) solid rgba(map-get(colors.$primary-color, black), .12);
    border-radius: utils.rem(10);
    box-shadow: none;
  }

  &__message-section {
    display: flex;
    margin-bottom: utils.rem(30);
    margin-top: utils.rem(28);

    &--image {
      margin-right: utils.rem(16);
    }

    &--wrapper {

      & > p:first-child {
        padding-bottom: utils.rem(10);
      }

      & > p:last-child {
        padding-top: utils.rem(10);
      }
    }
  }
}
