@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.template-details {
  width: 100%;
  $block: &;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: utils.rem(45);
    @include mixins.svg-hover;

    > h5 {
      color: rgba(map-get(colors.$primary-color, black), .87);
    }

    > svg {
      color: map-get(colors.$primary-color, primary);
    }
  }

  &__actions {
    margin-top: utils.bu(5);
    padding-left: utils.rem(45);
  }

  .form-builder__content {
    margin-top: 0;
  }

  > .details-section {

    > .card {
      padding: utils.rem(60) utils.rem(70);
    }
  }

  &--editMode {
    #{$block}__header {

      svg {
        color: rgba(map-get(colors.$text-color, secondary), .87);
      }
    }
  }
}
