@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.duplicate-event {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__details {
    margin-top: utils.rem(10);
    margin-bottom: utils.rem(50);
    line-height: utils.rem(28);

    @include mixins.set-font-family('SemplicitaPro');
  }

  &__message {
    margin-bottom: utils.bu(2);
  }

  &__list {
    list-style: initial;
    padding-left: utils.bu(2);
  }

  &__reason {
    margin-top: utils.bu(2);
  }

  &__form {
    width: 100%;
    margin-bottom: utils.rem(50);
    @include mixins.set-font-family('SemplicitaPro');

    .text-area {
      height: utils.rem(70);
    }
  }

  & > .button-group {
    width: utils.rem(162);

    & > .button + .button {
      letter-spacing: utils.rem(1.25);
    }
  }
}
