@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.form-builder {
  width: 100%;

  &__content {
    margin-top: utils.rem(30);
    width: 100%;
    display: flex;
  }

  &__sections {
    flex: 2;
  }

  &__add-section {
    display: flex;
    align-content: center;
    margin-top: utils.rem(30);

    > svg {
      color: map-get(colors.$primary-color, primary);
    }

    > small {
      font-weight: bold;
      margin-left: utils.rem(15);
    }
  }
}
