@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.widget-selector {
  width: 0;
  height: 0;
  visibility: hidden;
  content-visibility: hidden;
  @include mixins.transition(width, height);

  .accordion {
    margin-bottom: 0;
  }

  &__widgets {
    display: flex;
    flex-wrap: wrap;
  }

  &--open {
    visibility: visible;
    content-visibility: visible;
    height: fit-content;
    width: utils.rem(258);
    margin-right: utils.bu(4);
  }
}

.selector-item {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: utils.rem(129);
  height: utils.rem(110);
  border-right: utils.rem(1) solid rgba(map-get(colors.$border-color, input), .2);
  border-bottom: utils.rem(1) solid rgba(map-get(colors.$border-color, input), .2);

  &:nth-of-type(even) {
    border-right: none;
  }

  &__title {
    width: utils.rem(59);
    text-align: center;
    margin-top: utils.rem(12);
    color: rgba(map-get(colors.$primary-color, black), .87);
    font-weight: 400;
    font-size: utils.rem(8);
    letter-spacing: utils.rem(.4);
    @include mixins.set-font-family('SemplicitaPro');
  }
}
