@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.dashboard {
  width: 100%;
  display: flex;

  &__actions {
    flex: 1.5;
  }

  &__upcoming {
    flex: 1;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    margin-top: utils.rem(36);
    gap: utils.rem(30);
  }

  &__title {
    color: map-get(colors.$text-color, rich-black);
    font-weight: 510;
    font-size: utils.rem(34);
    @include mixins.set-font-family('SemplicitaPro');
  }

  &__badges {
    display: flex;
    align-items: center;
    margin-right: utils.rem(25);

    svg {
      margin-left: utils.rem(3);
      color: map-get(colors.$primary-color, primary);
      cursor: pointer;
      height: utils.rem(15);
      width: utils.rem(15);
    }
  }

  &__extras {
    height: utils.rem(32);
    width: utils.rem(32);
    margin-right: utils.rem(11);
    border-radius: 50%;
    border: utils.rem(1) solid map-get(colors.$primary-color, primary);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & > p {
      font-weight: 400;
      font-size: utils.rem(14);
      color: map-get(colors.$primary-color, primary);
      @include mixins.set-font-family('SemplicitaPro');
    }
  }
}

.tooltip__content {
  padding: utils.bu(1);
  font-weight: 700;
  color: map-get(colors.$primary-color, black);
  @include mixins.set-font-family('SemplicitaPro');
}
