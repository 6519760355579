@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.dropdown-properties {

  &__items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: utils.rem(10);
  }

  .radio-group__label {
    padding-left: 0;
  }

  .radio-group__buttons {
    margin-top: utils.rem(13);
  }

  .button-group {
    margin-top: utils.bu(5);
    align-items: center;
  }
}

.selection-field {
  width: utils.rem(100);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: utils.rem(1) solid rgba(map-get(colors.$primary-color, black), .12);
  border-radius: utils.rem(10);
  padding: utils.rem(12);
  text-align: center;
  margin-bottom: utils.bu(1);
  @include mixins.svg-hover;

  > small {
    color: rgba(map-get(colors.$text-color, secondary), .87);
  }

  > svg {
    color: map-get(colors.$primary-color, error);
    position: absolute;
    top: utils.rem(-3);
    right: utils.rem(-3);
  }
}
