@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.dynamic-points-config-template {

  .table-cell {

    & > svg {
      color: map-get(colors.$primary-color, white);
    }
  }

  .add-action {
    margin-top: utils.bu(3);
    margin-left: utils.bu(6);
    margin-bottom: utils.rem(2);
  }

  .details-section {

    &__header {

      svg {
        color: map-get(colors.$primary-color, primary);
      }
    }
  }

  .table-row {

    &:hover {

      svg {
        color: map-get(colors.$primary-color, primary);
      }
    }
  }
}
