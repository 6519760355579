@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.add-points-configuration {
  width: 100%;

  .accordion {
    margin-bottom: utils.rem(50);
    width: utils.rem(917);
  }

  .form-row:first-child {
    margin-top: 0;
  }

  &__requirements {
    width: utils.rem(512);
  }
}
