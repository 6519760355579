@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.wizard-layout {
  position: relative;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  padding-bottom: utils.bu(2);
  $block: &;

  &__context {
    display: flex;
    position: fixed;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 207px);
    overflow-y: scroll;
    padding: 0 utils.bu(2) utils.rem(76) utils.rem(10);
    margin-left: utils.rem(-10);
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  &__content {
    flex: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: utils.rem(323);

    #{$block}__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: utils.bu(5);
      margin-left: utils.rem(45);
      margin-right: utils.rem(45);
    }

    &--noMargin {
      margin-left: utils.rem(0);
      margin-right: utils.rem(356);
    }

    &--full {
      margin-right: 0;
    }
  }
}
