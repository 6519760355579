@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.dashboard-card {
  display: flex;
  border-radius: utils.rem(10);
  height: utils.rem(163);
  width: utils.rem(336);
  background-color: map-get(colors.$primary-color, white);
  border: utils.rem(1) solid rgba(map-get(colors.$primary-color, black), .12);
  padding: utils.rem(22) utils.rem(15) utils.rem(29) utils.rem(25);
  cursor: pointer;
  justify-content: space-between;

  &--disabled {
    cursor: not-allowed;
    opacity: .7;
  }

  &__detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > h4 {
      padding-top: utils.rem(20);
      font-size: utils.rem(22);
      color: map-get(colors.$primary-color, black);
      font-weight: 400;
    }

    > a {
      color: map-get(colors.$primary-color, primary);
      font-size: utils.rem(14);
      font-weight: 274;
      padding-bottom: utils.rem(12);
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
