@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.file-upload-properties {

  &__types-label {
    margin-bottom: utils.rem(20);
    font-weight: 400;
    color: rgba(map-get(colors.$primary-color, black), .87);
    @include mixins.set-font-family("SemplicitaPro");
  }

  &__types {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: utils.rem(12);

    .checkbox {
      width: 45%;

      &__label {
        font-size: utils.rem(12);
        color: rgba(map-get(colors.$text-color, secondary), .87);
        @include mixins.set-font-family("SemplicitaPro");
      }
    }
  }

  .radio-group__label {
    padding-left: 0;
  }

  .radio-group__buttons {
    margin-top: utils.rem(13);
  }

  .button-group {
    margin-top: utils.bu(5);
    align-items: center;
  }
}
