@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/extra" as extras;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

$badge-size: utils.rem(45);
$lg-badge-size: utils.rem(88);
$sml-badge-size: utils.rem(32);
$mdm-badge-size: utils.rem(70);

.badge {
  $block: &;

  flex-direction: column;
  cursor: pointer;
  @include mixins.flex-center();

  &__icon {
    width: $badge-size;
    height: $badge-size;
    background-color: map-get(colors.$primary-color, white);
    border-radius: map-get(extras.$border-radius, circular);

    > img,
    > svg {
      border-radius: map-get(extras.$border-radius, circular);
      width: 100%;
      height: 100%;
    }
  }

  &__showLabel {
    font-size: 0;
  }

  &__title {
    margin-top: utils.bu(2);
    @include mixins.set-font-family('SemplicitaPro');
  }

  &--large {
    #{$block}__icon {
      width: $lg-badge-size;
      height: $lg-badge-size;
      border-radius: map-get(extras.$border-radius, circular);
    }
  }

  &--medium {
    #{$block}__icon {
      width: $mdm-badge-size;
      height: $mdm-badge-size;
      border-radius: map-get(extras.$border-radius, circular);
    }
  }

  &--small {
    margin-right: utils.rem(11);
    margin-bottom: 0;

    #{$block}__icon {
      width: $sml-badge-size;
      height: $sml-badge-size;
    }
  }
}
