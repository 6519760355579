@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > h3 {
    margin-top: utils.bu(3);
    margin-bottom: utils.rem(50);
    font-size: utils.rem(34);
    font-weight: 400;
  }

  > p {
    text-align: center;
    color: rgba(map-get(colors.$primary-color, black), .87);
    margin-bottom: utils.rem(50);
  }

  .button {
    margin: auto;
    width: utils.rem(168);
  }
}
