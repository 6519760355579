@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/extra" as extras;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.organisation-selection {
  flex-direction: column;
  @include mixins.flex-center();

  &__organisations {
    width: 100%;
    margin-bottom: utils.rem(50);
    max-height: utils.rem(300);
    overflow-y: scroll;
  }

  &__button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: utils.rem(15) utils.rem(30);
    border: utils.rem(1) solid rgba(map-get(colors.$primary-color, black), .12);
    border-radius: utils.rem(7);

    & + & {
      margin-top: utils.rem(30);
    }

    &-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
      @include mixins.set-font-family('SemplicitaPro');

      & > label {
        font-size: utils.rem(16);
      }

      & > small {
        color: map-get(colors.$text-color, dark-gray);
      }
    }

    &-ellipse {
      width: utils.rem(46);
      height: utils.rem(46);
      margin-right: utils.rem(19);
      background-color: map-get(colors.$background-color, profile-image);
      color: map-get(colors.$primary-color, white);
      border-radius: map-get(extras.$border-radius, circular);
      @include mixins.flex-center();

      > img {
        width: 100%;
        height: 100%;
        border-radius: map-get(extras.$border-radius, circular);
      }
    }

    & > img,
    & > svg {
      margin-left: auto;
    }
  }

  & > .button {
    max-width: utils.rem(232);
  }
}
