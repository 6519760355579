@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.add-competition {

  .form-action {
    margin-top: utils.rem(20);
    width: utils.rem(168);
  }
}

.table-cell {

  & > svg {
    color: map-get(colors.$primary-color, white);
  }
}

.add-competition-details,
.add-competition-banking-details {
  padding: utils.bu(4) utils.bu(3.5) utils.bu(3.5);

  .form-row {

    &--fluid-spaced,
    &--single-row {
      margin-top: utils.rem(45);
    }
  }

  .form-row:first-child {
    margin-top: 0;
  }
}

.add-competition-description {

  .text-area textarea:focus {
    border-bottom: none;
  }
}

.add-competition-members,
.add-competition-classes {

  .add-action {
    margin-top: utils.bu(3);
    margin-left: utils.bu(6);
    margin-bottom: utils.rem(2);
  }

  .table {

    .table-cell,
    .table-heading {

      .select,
      .input input {
        width: utils.rem(100);

        @include mixins.breakpoint(x-large) {
          width: unset;
        }
      }
    }

    td.table-cell:first-child {
      width: utils.rem(20);
      padding-left: utils.bu(1);
    }
  }

  .table-row {

    &:hover {

      svg {
        color: map-get(colors.$primary-color, primary);
      }
    }
  }
}
