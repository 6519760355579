@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.banking-details {
  width: 100%;

  .details-section__header {

    svg {
      color: map-get(colors.$primary-color, primary);
    }
  }

  &__content-wrapper {
    display: flex;
    width: 100%;

    .form {
      width: utils.rem(750);
      margin: 0 utils.rem(40) utils.rem(40) utils.rem(40);
    }
  }

  &__form-wrapper {
    display: flex;
    flex-direction: column;

    &--grey {

      .details-section__header {

        svg {
          color: map-get(colors.$background-color, disabled);
        }
      }
    }
  }

  &__actions {
    margin-top: utils.rem(40);
    margin-left: utils.rem(45);
    margin-right: utils.rem(45);
    display: flex;
    justify-content: space-between;
  }
}
