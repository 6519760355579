@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.add-event {

  .accordion {
    margin-bottom: utils.rem(50);
  }

  .form-action {
    margin-top: utils.rem(20);
    width: utils.rem(168);
  }

  &__documents {
    margin-top: utils.bu(2.5);
    margin-bottom: utils.bu(6);
    gap: utils.bu(2.5);
    display: flex;
    flex-direction: column;
  }
}

.add-event-details {
  padding: utils.bu(4) utils.bu(3.5) utils.bu(3.5);

  &__times {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > * + * {
      margin-left: utils.rem(75);
    }

    input {
      width: 100%;
    }

    .input__icon {
      margin-left: utils.rem(-30);
    }
  }

  .form-row:first-child {
    margin-top: 0;
  }
}

.add-event-description {

  .text-area textarea:focus {
    border-bottom: none;
  }
}

.add-event-members,
.add-event-classes {

  .new-row {
    margin-top: utils.bu(3);
    margin-left: utils.bu(6);
    margin-bottom: utils.rem(2);
    display: flex;
    align-items: center;
    cursor: pointer;
    @include mixins.svg-hover();

    > svg {
      color: map-get(colors.$primary-color, primary);
    }

    &__title {
      font-size: utils.rem(12);
      letter-spacing: utils.rem(.15);
      margin-left: utils.bu(2);
      @include mixins.set-font-family('SemplicitaPro');
    }
  }
}
