@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/extra" as extras;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

$badge-size: utils.rem(88);
$mdm-badge-size: utils.rem(70);

.organisation-badges {
  padding: utils.rem(10);
  align-items: center;
  justify-content: center;

  & > header {
    margin-top: utils.rem(30);
  }

  &__badges {
    margin-top: utils.rem(48);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: utils.rem(262);

    & > .tooltip__wrapper {
      margin-right: utils.rem(21);
      margin-bottom: utils.rem(40);

      &:nth-of-type(3n+3) {
        margin-right: 0;
      }

      &:nth-of-type(n+4) {
        margin-bottom: 0;
      }
    }
  }

  &__extra {
    height: $mdm-badge-size;
    width: $mdm-badge-size;
    border-radius: 50%;
    border: utils.rem(1) solid map-get(colors.$background-color, profile-image);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & > p {
      font-weight: 400;
      font-size: utils.rem(14);
      color: map-get(colors.$background-color, profile-image);
      @include mixins.set-font-family('SemplicitaPro');
    }

    p {
      color: map-get(colors.$background-color, profile-image);
    }
  }
}

.tooltip__content {
  font-weight: 400;
  font-size: utils.rem(14);
  letter-spacing: utils.rem(.4);
  @include mixins.set-font-family('SemplicitaPro');
}
