@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.schedule-heats {
  margin-top: utils.rem(30);

  &__heat {
    display: flex;
    align-items: center;

    & + & {
      margin-top: utils.bu(1);
    }

    > svg {
      color: map-get(colors.$primary-color, primary);
    }
  }

  &__heats {
    margin-bottom: utils.rem(30);
  }

  &__info {
    display: flex;
    width: 57%;
  }

  &__checks {
    display: flex;
    width: 33%;

    .checkbox {
      flex-direction: row-reverse;
      justify-content: flex-end;

      &__label {
        margin-left: 0;
        margin-right: utils.bu(1);
      }
    }
  }

  &__heat-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-left: utils.rem(14);
    padding: utils.rem(9) utils.rem(17) utils.rem(9) utils.rem(14);
    border: utils.rem(1) solid rgba(0, 0, 0, .12);
    border-radius: utils.rem(10);
    @include mixins.set-font-family("SemplicitaPro");

    dl {
      display: flex;
      align-items: center;
      margin: 0;
      width: 100%;
    }

    dt {
      font-size: utils.rem(14);
    }

    dd {
      display: flex;
      margin-left: utils.rem(15);
      font-size: utils.rem(12);
      color: map-get(colors.$border-color, input);
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    width: 10%;

    & > article + article {
      margin-left: utils.bu(1);
    }

    svg {
      cursor: pointer;
      width: utils.rem(20);
      height: utils.rem(20);
      color: map-get(colors.$primary-color, primary);
    }
  }

  .add-action__title {
    font-weight: 400;
  }
}
