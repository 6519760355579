@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.add-schedule-heat {

  &__info {
    display: flex;
    flex-direction: column;
    margin-left: utils.bu(8);
    @include mixins.set-font-family("SemplicitaPro");

    & > * + * {
      margin-top: utils.rem(10);
    }

    &-label {
      font-weight: 400;
      letter-spacing: utils.rem(.4);
    }

    .checkbox {

      &__indicator {
        width: utils.bu(2);
        height: utils.bu(2);
        border-width: utils.rem(1);
      }

      &__label {
        font-size: utils.rem(14);
      }
    }
  }

  & > .form {
    width: 100%;

    .form-row--fluid {
      justify-content: unset;

      &:nth-of-type(2) {
        width: 80%;

        & > .timepicker + .timepicker {
          margin-left: utils.bu(8);
        }
      }
    }

    .form-row:last-of-type {
      margin-top: utils.rem(25);
    }

    .input--shadowed {
      width: 50%;
    }

    .input__input-wrapper {
      padding-top: utils.bu(1);
      padding-block: utils.bu(1);
      margin-top: utils.rem(10);
    }
  }
}
