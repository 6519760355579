@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.add-entry-form-template {
  margin-top: utils.bu(4);
  width: 100%;
  align-self: baseline;

  > header {
    width: utils.rem(240);
    margin-bottom: utils.bu(3);
  }

  &__actions {
    align-self: baseline;
    margin-top: utils.bu(5);
    width: utils.rem(500);

    * + * {
      margin-left: utils.bu(2);
    }
  }
}
