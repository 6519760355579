@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.organisation-switch {

  &__organisation {
    display: flex;
    align-items: center;
    width: utils.rem(210);
    margin-left: utils.rem(70);
    letter-spacing: utils.rem(.1);
    color: rgba(map-get(colors.$primary-color, black), .87);

    &-item {

      &-name {
        display: flex;
        align-items: center;
        width: 100%;
        font-weight: 500;
        font-size: utils.rem(16);

        & > svg {
          margin-left: auto;
        }
      }
    }

    &-create-new {
      font-size: utils.rem(14);
      letter-spacing: utils.rem(1.25);

      &-icon {
        width: utils.rem(15);
        color: map-get(colors.$primary-color, primary);
      }
    }

    & > label {
      font-size: utils.rem(16);
      font-weight: 500;
      @include mixins.set-font-family('SimplicitaPro');
    }

    & > svg {
      margin-left: auto;
      color: map-get(colors.$text-color, rich-black);
    }
  }

  .dropdown-menu {
    z-index: 2;
  }
}
