@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/extra" as extras;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.schedule-section {
  padding: utils.rem(25);

  & > header {

    & > h4 {
      margin-bottom: utils.rem(45);
      font-weight: 274;
      font-style: normal;
      font-size: utils.bu(3);
      line-height: utils.bu(3);
      letter-spacing: utils.rem(.15);
      @include mixins.set-font-family("SF Pro");
    }
  }

  &__dates {
    display: flex;
    align-items: center;
    margin-bottom: utils.rem(15);

    label {
      font-weight: 400;
      font-size: utils.rem(17);
      line-height: utils.bu(2);
      letter-spacing: utils.rem(.4);
      @include mixins.set-font-family('SemplicitaPro');
    }
  }

  &__date {
    display: flex;
    justify-content: center;
    align-items: center;
    width: utils.rem(45);
    height: utils.rem(45);
    background-color: map-get(colors.$primary-color, primary);
    color: map-get(colors.$text-color, white);
    border-radius: map-get(extras.$border-radius, circular);
  }

  &__day {
    margin-left: utils.rem(15);
  }
}
