@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.add-organisation {
  width: 100%;

  .details-section__header {
    margin-bottom: 0;
  }

  &__content-wrapper {
    display: flex;
    width: 100%;

    .form {
      width: utils.rem(750);
      margin-bottom: utils.rem(40);
      margin-left: utils.rem(40);
    }
  }

  &__address-wrapper {
    width: 100%;
  }

  &__form-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: utils.rem(33);
  }

  &__actions {
    margin-top: utils.rem(40);
    margin-left: utils.rem(45);
    margin-right: utils.rem(45);
    display: flex;
    justify-content: space-between;
  }
}
