@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.organisation-details {

  .checkbox {
    align-self: baseline;
    margin-left: utils.bu(1);
    width: 50%;
    @include mixins.set-font-family('SemplicitaPro');
  }

  &__login-link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: map-get(colors.$text-color, black);
    @include mixins.set-font-family('SemplicitaPro');

    > a {
      margin-left: utils.bu(.5);
      font-size: utils.rem(12);
      font-weight: bold;
    }
  }

  .form-action {
    margin-left: auto;
    margin-right: auto;
    width: utils.rem(168);
    margin-bottom: utils.rem(35);
  }
}
