@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/extra" as extra;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.stepper {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: utils.rem(15);

  &--noMargin {
    justify-content: normal;
  }
}

.step {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .2;
  cursor: pointer;
  $block: &;

  &__index {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: map-get(extra.$border-radius, circular);
    background-color: map-get(colors.$primary-color, primary);
    height: utils.rem(35);
    width: utils.rem(35);
    color: map-get(colors.$primary-color, white);
  }

  &__label {
    font-size: utils.rem(14);
    letter-spacing: utils.rem(.4);
    color: map-get(colors.$primary-color, primary);
    margin-left: utils.rem(10);
    cursor: pointer;
  }

  &--active {
    opacity: 1;
  }

  &--error {
    #{$block}__index {
      background-color: map-get(colors.$primary-color, error);
    }

    #{$block}__label {
      color: map-get(colors.$primary-color, error);
    }
  }

  & + & {
    margin-left: utils.rem(45);
  }
}
