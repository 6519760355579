@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.organiser-account-details {
  padding: utils.bu(5) utils.rem(45);

  .form {
    margin-top: utils.bu(7.5);

    .input + .input {
      margin-left: utils.rem(52);
    }

    .form-row:nth-child(2) {
      align-items: flex-start;

      .input {
        width: 47%;
      }
    }
  }
}
