@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.organizer-layout {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: map-get(colors.$background-color, primary);

  &__content-section {
    background-color: map-get(colors.$background-color, primary);
    width: 100%;
    margin-left: utils.rem(100);

    & > .header {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }

  &__content {
    background-color: map-get(colors.$background-color, primary);
    margin-top: utils.rem(83);
    width: 100%;
    padding: utils.rem(60) utils.rem(60) utils.rem(60) utils.rem(70);
    flex-direction: column;
    @include mixins.flex-center();

    &-title {
      display: flex;
      align-self: baseline;
      align-items: baseline;
      margin-top: utils.rem(83);
      padding-top: utils.rem(60);
      z-index: 1;
      position: fixed;
      top: 0;
      margin-left: utils.rem(-10);
      width: 100%;
      background-color: map-get(colors.$background-color, primary);
      @include mixins.svg-hover();

      > svg {
        margin-left: utils.rem(10);
        color: map-get(colors.$text-color, rich-black);
        margin-right: utils.bu(2);
      }
    }

    &--gutterless {
      padding-top: 0;
    }
  }

  &__title-wrapper {
    padding-left: utils.rem(10);

    h6 {
      color: map-get(colors.$text-color, black);
      font-size: utils.rem(14);
      font-weight: 400;
      padding-top: utils.rem(6);
      @include mixins.set-font-family('SemplicitaPro');
    }

    &--back {
      padding-left: 0;
    }
  }
}
