@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.checkbox-properties {

  &__add {
    margin-top: utils.rem(20);
    margin-bottom: utils.bu(2);
  }

  .radio-group__label {
    padding-left: 0;
  }

  .radio-group__buttons {
    margin-top: utils.rem(13);
  }

  .button-group {
    margin-top: utils.bu(5);
    align-items: center;
  }
}
