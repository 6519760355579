@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.personal-details {
  padding-left: utils.rem(99);
  padding-bottom: utils.bu(6);
  padding-top: utils.rem(25);
  display: flex;

  &__icon {
    display: flex;
    align-items: center;
  }

  &__form {
    max-width: utils.rem(756);
    margin-left: utils.rem(100);
    flex: 2;

    > header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include mixins.svg-hover();

      > svg {
        color: map-get(colors.$primary-color, primary);
      }
    }

    &--editing {

      > header > svg {
        color: rgba(map-get(colors.$text-color, secondary), .87);
      }
    }

    .form {
      margin-top: utils.bu(7.5);

      .form-row {

        .input + .input {
          margin-left: utils.rem(52);
        }

        &:nth-child(2) {
          align-items: flex-start;

          .input {
            width: 46%;
          }
        }
      }
    }
  }
}
