@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.wizard {
  width: 100%;

  &__anchor {
    visibility: hidden;
    top: utils.rem(-200);
    position: absolute;
  }

  &__steps {
    margin-bottom: utils.bu(7.5);
  }

  &__content {
    min-height: 50vh;
  }

  &__error {
    margin-top: utils.bu(5);
    background-color: map-get(colors.$background-color, primary);
    border: utils.rem(1) solid map-get(colors.$primary-color, error);
    padding: utils.rem(13) utils.rem(33) utils.rem(17);
    @include mixins.set-font-family('SemplicitaPro');

    > small {
      font-weight: 700;
      color: map-get(colors.$primary-color, error);
    }

    > p {
      margin-top: utils.bu(1);
      font-size: utils.rem(12);
    }
  }
}
