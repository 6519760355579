@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/extra" as extras;

$mdm-badge-size: utils.rem(70);

.organisation-badge {
  width: utils.rem(220);
  height: utils.rem(150);
  margin-right: utils.rem(20);
  border-radius: utils.rem(5);

  &__default {
    background-color: map-get(colors.$background-color, profile-image);
    color: rgba(map-get(colors.$primary-color, black), .87);
    font-weight: 500;
    box-shadow: 0 utils.rem(2) utils.rem(4) rgba(0, 0, 0, .12);
    @include mixins.flex-center();

    & > label {
      font-size: utils.rem(16);
      font-weight: 500;
    }

    &--small {
      width: utils.rem(35);
      height: utils.rem(35);
      border-radius: utils.rem(3);
    }

    &--medium {
      width: $mdm-badge-size;
      height: $mdm-badge-size;
    }

    &--round {
      border-radius: map-get(extras.$border-radius, circular);
    }
  }

  &--small,
  img {
    width: utils.rem(35);
    height: utils.rem(35);
    border-radius: utils.rem(3);
    font-size: utils.rem(16);

    & + span {
      margin-left: 0;
      width: 100%;
    }
  }

  &--medium {
    width: $mdm-badge-size;
    height: $mdm-badge-size;

    img {
      width: $mdm-badge-size;
      height: $mdm-badge-size;
    }
  }

  &--round {

    img {
      border-radius: map-get(extras.$border-radius, circular);
      box-shadow: 0 utils.rem(2) utils.rem(4) rgba(0, 0, 0, .12);
    }
  }
}
