@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.schedule {

  &__content {
    display: flex;
    margin-top: utils.rem(33);

    & > section {
      width: 100%;
      margin-left: utils.rem(34);

      & > .card {
        padding: utils.rem(58) utils.rem(30) utils.rem(30) utils.rem(45);
      }
    }

    .tab {
      width: 100%;

      &--active {
        color: map-get(colors.$primary-color, primary);
      }
    }
  }

  &__actions {
    margin-top: utils.rem(51);

    .button-group {
      align-items: center;
    }
  }

  &__date {
    display: flex;
    align-items: center;
    letter-spacing: utils.rem(.4);
    font-weight: 400;
    @include mixins.set-font-family("SemplicitaPro");

    & > svg {
      color: map-get(colors.$primary-color, primary);
      margin-right: utils.bu(1);
    }
  }

  &__info-section {

    &-actions {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    h3 {
      margin-bottom: utils.rem(67);
    }

    & > .card > .form {
      width: 50%;

      .input__input-wrapper {
        padding-top: utils.bu(1);
        padding-block: utils.bu(1);
        margin-top: utils.rem(10);
      }
    }

    .radio-group {
      display: flex;

      &__label {
        margin-right: utils.bu(4);
        padding: 0;
      }

      &__buttons {
        margin-top: 0;
        padding: 0;
      }
    }
  }

  &__additional-slots {
    margin-top: utils.rem(70);

    & > .tabs {
      margin-top: utils.rem(20);
    }
  }

  & > p {
    font-size: utils.bu(2);
    line-height: utils.bu(3);
    font-weight: 300;
    @include mixins.set-font-family("SemplicitaPro");
  }
}

.save-action {
  display: flex;
  align-items: center;
  cursor: pointer;
  @include mixins.svg-hover;

  > svg {
    color: map-get(colors.$primary-color, primary);
  }

  &__title {
    margin-left: utils.rem(15);
    @include mixins.set-font-family('SemplicitaPro');

    &--disabled {
      color: map-get(colors.$text-color, secondary);
    }
  }

  &--disabled {
    cursor: default;

    > svg {
      cursor: default;
      color: map-get(colors.$background-color, secondary);

      &:hover {
        fill-opacity: 1;
        opacity: 1;
      }
    }
  }
}
