@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.add-entry-form {
  width: 100%;

  > .accordion {
    margin-bottom: utils.rem(50);
    width: utils.rem(917);
  }

  .form-row:first-child {
    margin-top: 0;
  }

  &__requirements {
    width: utils.rem(512);
  }

  &__content {
    margin-top: utils.bu(7.5);
  }

  &__title {
    color: rgba(map-get(colors.$primary-color, black), .87);
  }

  &__subtitle {
    width: utils.rem(660);
    margin-top: utils.bu(2.5);
    font-size: utils.rem(14);
    @include mixins.set-font-family('SemplicitaPro');
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: utils.bu(5);
    margin-left: utils.rem(45);
    margin-right: utils.rem(45);
  }
}
