@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.delete-organisation {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__message {
    margin-bottom: utils.rem(40);

    p {
      @include mixins.set-font-family('SemplicitaPro');
    }
  }
}
