@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.remove-template {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__message {
    margin-top: utils.rem(10);
    margin-bottom: utils.rem(30);
    text-align: center;
    line-height: utils.rem(28);

    @include mixins.set-font-family('SemplicitaPro');

    span {
      font-weight: bold;
    }
  }

  & > .button-group {
    width: utils.rem(162);

    & > .button + .button {
      letter-spacing: utils.rem(1.25);
    }
  }
}
