@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.widget-properties {
  width: 0;
  height: 0;
  visibility: hidden;
  @include mixins.transition(width, height);

  &__action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: map-get(colors.$primary-color, error);
    @include mixins.svg-hover();
  }

  .card {
    padding: utils.bu(5) utils.bu(3);
    width: 0;
    height: 0;
    @include mixins.transition(width, height);

    .form-row:first-of-type {
      margin-top: 0;
    }
  }

  &--open {
    visibility: visible;
    height: fit-content;
    width: utils.rem(260);
    flex: .5;
    margin-left: utils.bu(4);

    .card {
      height: fit-content;
      width: utils.rem(260);
    }
  }
}
