@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.add-schedule-timeslot {

  &__date {
    display: flex;
    align-items: center;
    letter-spacing: utils.rem(.4);
    font-weight: 400;
    @include mixins.set-font-family("SemplicitaPro");

    & > svg {
      color: map-get(colors.$primary-color, primary);
      margin-right: utils.bu(1);
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  h3 {
    margin-bottom: utils.rem(67);
  }

  & > .card > .form {
    width: 50%;

    .input__input-wrapper {
      padding-top: utils.bu(1);
      padding-block: utils.bu(1);
      margin-top: utils.rem(10);
    }
  }

  .radio-group {
    display: flex;

    &__label {
      margin-right: utils.bu(4);
      padding: 0;
    }

    &__buttons {
      margin-top: 0;
      padding: 0;
    }
  }
}
