@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.competitions-list {
  width: 100%;
  display: flex;

  &__filters {
    flex: 1;
    max-width: utils.rem(255);

    > .tabs {
      width: 100%;
      align-items: baseline;

      > .tab {
        width: 100%;
      }
    }
  }

  &__list {
    flex: 3;
    display: flex;
    flex-wrap: wrap;
    margin-left: utils.rem(20);

    .event-card {
      margin-bottom: utils.rem(30);
      margin-left: utils.rem(30);
    }
  }
}
