@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.event-card {
  display: flex;
  flex-direction: column;
  width: utils.rem(281);
  border-radius: utils.rem(10);
  background-color: map-get(colors.$primary-color, white);

  &__image {
    width: 100%;
    height: utils.rem(281);

    > img {
      border-top-left-radius: utils.rem(10);
      border-top-right-radius: utils.rem(10);
      height: 100%;
      width: 100%;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: utils.rem(18) utils.rem(18) utils.rem(2) utils.rem(18);

    > small {
      width: 100%;
      margin-bottom: utils.bu(2);
      font-size: utils.rem(8);
      font-weight: 400;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__event-name {
    display: flex;
    justify-content: space-between;
    margin-bottom: utils.bu(2);

    > h6 {
      color: rgba(map-get(colors.$text-color, tuna), .8);
      font-style: normal;
      font-size: utils.rem(14);
      max-width: utils.rem(165);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__action {
    display: flex;
    padding: utils.rem(8) utils.rem(8) utils.rem(8) utils.rem(18);
    border-top: utils.rem(.5) solid map-get(colors.$border-color, card);

    > .button {
      padding: 0;
      text-align: start;
      font-weight: 700;
      letter-spacing: utils.rem(1.25);
    }
  }
}
