@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.entries-list {

  &__actions {
    margin-top: utils.rem(40);
    margin-left: utils.rem(45);
    margin-right: utils.rem(45);
    display: flex;
    justify-content: space-between;
  }
}
